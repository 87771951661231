function checkPasswordPolicy(string, regexString) {
    var PASSWORD_POLICY_REGEX_ARRAY = JSON.parse(regexString);
    var errorMessage = '';
    PASSWORD_POLICY_REGEX_ARRAY.forEach(function (r) {
        if (!testRegex(string, r['regex_pattern_for_js'])) {
            errorMessage += '<strong>' + r['message'] + '</strong><br/>';
        }
    });
    return errorMessage;
}

function testRegex(string, regex) {

    var regexExp = new RegExp(regex);

    if (regexExp.test(string)) {
        return true;
    }
    return false;

}

function checkPassword(){
    let password = $("#password");
    let password_repeat = $("#password_repeat");
    let password_alert =  $('#password-policy-info');
    let password_value = password.val();
    let password_repeat_value = password_repeat.val();
    let regex_pattern = password_policy_regex;
    let error_message = '';

    //No error is shown if password is blank
    if(password_value === ""){
        password.removeClass("error-border success-border");
        password_repeat.removeClass("error-border success-border");
        password_alert.hide();
        return false;
    }

    //Check if the password is suitable for our password policy.
    error_message = checkPasswordPolicy(password_value,regex_pattern);
    if(error_message !== ''){
        password.addClass("error-border");
    } else {
        password.removeClass("error-border").addClass("success-border");
    }

    //Checking to see if password and password repeat are the same
    if(password_value !== password_repeat_value){
        error_message += "Password and password repeat must be same";
        password_repeat.addClass("error-border");
    }

    //Error borders are hidden if there are no errors
    if(error_message === ''){
        password_alert.html("Your password meets our password policy.").removeClass("alert-warning").addClass("alert-success").show();
        password.removeClass("error-border").addClass("success-border");
        password_repeat.removeClass("error-border").addClass("success-border");
    } else {
        password_alert.html(error_message).removeClass("alert-success").addClass("alert-warning").show();
    }
}


/*
function showPassword(){
    var password = $('#password');
    var type = password.attr('type');
    if(type === 'text'){
        password.attr('type', 'password');
    }else{
        password.attr('type', 'text');
    }
}

 */
window.checkPassword = checkPassword;
window.checkPasswordPolicy = checkPasswordPolicy;




